@import 'fontsizes';
@import 'sizes';
@import 'colors';

.table-field {
    font-size: $fontsizes_xs;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    @media (min-width: $breakpoint_md) {
        font-size: $fontsizes_sm;
    }
}

.default-link:hover {
    text-decoration: underline;
}

.default-link-disabled:hover {
    text-decoration: none;
}

.kanban-card-label {
    font-size: $fontsizes_xs;
    margin-bottom: 2px;
    margin-top: 8px;
    line-height: 1.35em;
    color: $colors_grey-400;
    font-weight: bold;
}
.kanban-record-label {
    font-size: $fontsizes_xs;
    font-weight: bold;
    color: $colors_grey-500;
}
.kanban-card-field-text {
    font-size: $fontsizes_xs;
    line-height: 1.2em;
    color: $colors_grey-500;
}
