$fontsizes_xs: 0.75rem /** 12px */;
$fontsizes_sm: 0.875rem;
$fontsizes_sm: 0.875rem /** 14px */;
$fontsizes_smmd: 1rem /** 16px */;
$fontsizes_md: 1.125rem /** 18px */;
$fontsizes_mdlg: 1.25rem /** 20px */;
$fontsizes_lg: 1.5rem /** 24px */;
$fontsizes_xl: 2rem /** 32px */;
$fontsizes_xxl: 2.5rem /** 40px */;
$fontsizes_xxxl: 3rem /** 48px */;
