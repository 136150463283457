.atom-button {
    font-size: 14px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 2px 14px 3px;
    height: 36px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    font-family: 'Proxima Nova';

    transition: 0.2s all ease-in-out;
    .icon svg,
    .children {
        transition: 0.2s all ease-in-out;
    }

    .spinner {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 14px;
        height: 14px;
        margin: auto;
    }
    &.isLoading {
        transition: none;
        cursor: not-allowed;
        .icon svg,
        .children {
            transition: none;
            visibility: hidden;
        }
        .spinner {
            display: block;
        }
    }
    &.disabled,
    &.isLoading {
        cursor: not-allowed;
    }
    .children {
        white-space: nowrap;
    }

    &.theme-neutral {
        &.type-primary:not(.disabled) {
            &.theme-dark {
                background: #333c43;
            }
            .icon svg,
            .spinner svg {
                color: rgba(255, 255, 255, 0.6);
            }
            .children {
                color: rgba(255, 255, 255, 0.8);
            }
            &:hover {
                .icon svg,
                .spinner svg,
                .children {
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
        &.type-primary.disabled {
            background: #d7dbe1;
            .icon svg,
            .spinner svg,
            .children {
                color: rgba(255, 255, 255, 1);
            }
        }
    }
    &.type-primary {
        &.theme-stacker {
            background: #465dd8;
            &:hover:not(.disabled) {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #465dd8;
            }
        }
        color: #ffffff;
        &.disabled,
        &.disabled:hover {
            background: #d7dbe1;
        }
    }
    &.type-primary.theme-dark {
        background: #333c43;
        color: rgba(255, 255, 255, 0.8);
        &:hover {
            color: white;
        }
        &.disabled,
        &.disabled:hover {
            background: #d7dbe1;
        }
    }

    &.type-secondary {
        border-radius: 5px;
    }

    &.theme-dark.type-secondary {
        background: rgba(255, 255, 255, 0.1);
        .icon svg,
        .spinner svg {
            color: rgba(255, 255, 255, 0.3);
        }
        .children {
            color: rgba(255, 255, 255, 0.6);
        }
        &:hover:not(.disabled) {
            background: rgba(255, 255, 255, 0.14);
            .icon svg,
            .spinner svg,
            .children {
                color: rgba(255, 255, 255, 1);
            }
        }
        &.disabled,
        &.disabled:hover {
            background: transparent;
            border: 1px solid rgba(255, 255, 255, 0.14);
        }
    }
    &.theme-brand.type-secondary {
        background: rgba(255, 255, 255, 0.14);
        border: 1px solid transparent;
        .icon svg,
        .spinner svg {
            color: rgba(255, 255, 255, 0.4);
        }
        .children {
            color: rgba(255, 255, 255, 0.7);
        }
        &:hover:not(.disabled) {
            background: rgba(255, 255, 255, 0.22);
            .icon svg,
            .spinner svg,
            .children {
                color: rgba(255, 255, 255, 1);
            }
        }
        &.disabled,
        &.disabled:hover {
            background: transparent;
            border: 1px solid rgba(255, 255, 255, 0.14);
        }
    }

    &.theme-light.type-secondary,
    &.theme-stacker.type-secondary {
        border: 1px solid #e7eaef;
        background: white;
        font-weight: 400;
        .icon svg,
        .spinner svg {
            color: #adb0b5;
        }
        .children {
            color: #333c43;
        }
        &:hover {
            background: #f9f9fa;
        }
        &.disabled,
        &.disabled:hover {
            background: #d7dbe1;
            .icon svg,
            .spinner svg,
            .children {
                color: white;
            }
        }
    }

    &.type-link {
        padding: 2px 5px 3px;
        background: transparent;
        // icon + dropdown only, we reduce the spacing
        .icon + .spacer + .icon {
            margin-left: -3px;
        }

        &.theme-light,
        &.theme-stacker {
            .icon svg,
            .spinner svg {
                color: #adb0b5;
            }
            .children {
                color: #333c43;
            }
        }
        &.theme-dark,
        &.theme-brand {
            .icon svg,
            .spinner svg,
            .children {
                color: rgba(250, 253, 255, 0.6);
            }
            .icon.dropdown svg {
                color: rgba(250, 253, 255, 0.8);
            }

            &:hover {
                .icon svg,
                .spinner svg,
                .children {
                    color: white;
                }
            }
        }
    }

    border-radius: 5px;
    border-width: 0;
    outline: none;
}
