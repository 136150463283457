.raf-user-bar {
}

.raf-avatar--circle {
    width: 40px !important;
    height: 40px !important;
}

.raf-textarea__textarea {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.raf-activity__content {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
}
